function App() {
  return (
    <>
      {/* <!--header section start--> */}
      <header class="header custom-header">
        {/* <!--start navbar--> */}
        <nav class="navbar navbar-expand-lg fixed-top custom-nav white-bg">
          <div class="container">
            <a class="navbar-brand" href="index-3.html">
              <img
                src="img/lagbajalogo.png"
                width="120"
                alt="logo"
                class="img-fluid"
              />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="ti-menu"></span>
            </button>

            <div
              class="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                  <a
                    class="nav-link page-scroll"
                    href="#home"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Home
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link page-scroll"
                    href="#about"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    About
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link page-scroll"
                    href="#contact"
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* <!--end navbar--> */}
      </header>
      {/* <!--header section end--> */}

      <div class="main">
        {/* <!--hero section start--> */}
        <section class="hero-section hero-section-3 ptb-100">
          {/* <!--animated circle shape start--> */}
          <div class="circles">
            <div class="point animated-point-1"></div>
            <div class="point animated-point-2"></div>
            <div class="point animated-point-3"></div>
            <div class="point animated-point-4"></div>
            <div class="point animated-point-5"></div>
            <div class="point animated-point-6"></div>
          </div>
          {/* <!--animated circle shape end--> */}
          <div class="container" id="animation-area-1">
            <div class="row align-items-center justify-content-center">
              <div class="col-md-6 col-lg-6 col-xl-6">
                <div class="hero-content section-title text-center text-lg-start my-5 mt-lg-0">
                  {/* <span class="text-uppercase color-secondary fw-bold">
                    Best Design Quality
                  </span> */}
                  <h1
                    style={{
                      fontSize: "72px",
                      fontWeight: "500",
                      lineHeight: "74px",
                    }}
                  >
                    Your safe space to speak up.
                  </h1>
                  <p
                    class="lead mt-5"
                    style={{ color: "#000", fontWeight: "400" }}
                  >
                    Discover a safe and secure platform to Connect with others
                    who understand your challenges. Share your feelings without
                    judgment.
                  </p>
                  <div
                    class="action-btn mt-4"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <img
                      src="img/google-store.png"
                      style={{ marginRight: "10px" }}
                    />

                    <img src="img/apple-store.png" />
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-lg-5 offset-xl-1 align-self-sm-end">
                <div class="hero-img position-relative">
                  <div class="image-bg-positioning">
                    <img src="img/lagbaja-1.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section class="pb-100">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                <div class="single-fact p-3 text-center">
                  <h3 class="title display-3 fw-semibold">50K</h3>
                  <p class="mb-0">
                    People are already reading this book and improving!
                  </p>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                <div class="single-fact p-3 text-center">
                  <h3 class="title display-3 fw-semibold">96% </h3>
                  <p class="mb-0">
                    Satisfaction rate comes from our awesome customers.
                  </p>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
                <div class="single-fact p-3 text-center">
                  <h3 class="title display-3 fw-semibold">4.9</h3>
                  <p class="mb-0">
                    Average customer ratings we have got all over internet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <!--about us section start--> */}
        <section id="about" class="about-us ptb-100">
          <div class="container">
            <div class="row align-items-center justify-content-between g-4">
              <div class="col-md-4">
                <div class="about-content-right">
                  <img
                    src="img/lagbaja-2.png"
                    alt="about us"
                    class="img-fluid"
                  />
                </div>
              </div>
              <div class="col-md-7 offset-xl-1">
                <div class="about-content-left section-heading">
                  <h2
                    style={{
                      fontSize: "72px",
                      fontWeight: "500",
                      lineHeight: "74px",
                    }}
                  >
                    We are Committed to Your Privacy
                  </h2>

                  <div class="single-feature mb-4 mt-5">
                    <div
                      class="icon-box-wrap d-flex align-items-center mb-2"
                      style={{ marginBottom: "40px" }}
                    >
                      <p
                        style={{
                          fontSize: "16px",
                          fontWeight: "400",
                          lineHeight: "28px",
                          color: "#000",
                          fontWeight: "400",
                        }}
                      >
                        Lagbaja is more than just an application; its is your
                        digital sanctuary, and a safe space for your thoughts
                        and feelings. We believe everyone deserves a place to
                        express themselves openly and honestly. Our commitment
                        to your privacy is absolute.
                      </p>
                    </div>
                    <h5>Download App From</h5>
                    <div
                      class="action-btn mt-4"
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <img
                        src="img/google-store.png"
                        style={{ marginRight: "10px" }}
                      />

                      <img src="img/apple-store.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="pricing" class="package-section ptb-100">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-11 col-lg-8">
                <div class="section-heading text-center mb-5">
                  <h2 style={{ fontSize: "68px", fontWeight: "500" }}>
                    How it works
                  </h2>
                  <p style={{ color: "#000", fontWeight: "400" }}>
                    Lagbaja is more than just an application; its is your
                    digital sanctuary, and a safe space for your thoughts and
                    feelings. We believe everyone deserves a place to express
                    themselves openly and honestly. Our commitment to your
                    privacy is absolute.
                  </p>
                </div>
              </div>
            </div>
            <div class="row justify-content-center g-4">
              <div class="col-lg-4 col-md-6">
                <div class="text-center p-5">
                  <img
                    src="img/download-icon.png"
                    style={{ marginTop: "3rem", marginBottom: "5rem" }}
                  />
                  <h5 class="mb-2">Download the app </h5>

                  <div
                    class="card-body p-0"
                    style={{ color: "#000", fontWeight: "400" }}
                  >
                    Download the Lagbaja Application from Google play store or
                    IOS store.
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="text-center p-5">
                  <img
                    src="img/create-account.png"
                    style={{ marginTop: "3rem", marginBottom: "5rem" }}
                  />
                  <h5 class="mb-2">Signup and create an account </h5>
                  <div
                    class="card-body p-0"
                    style={{ color: "#000", fontWeight: "400" }}
                  >
                    Signup and create an anonymous account.
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="text-center p-5">
                  <img
                    src="img/chat-icon.png"
                    style={{ marginTop: "3rem", marginBottom: "5rem" }}
                  />
                  <h5 class="mb-2">Chat with community </h5>

                  <div
                    class="card-body p-0"
                    style={{ color: "#000", fontWeight: "400" }}
                  >
                    Chat with the Lagbaja Community.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--about us section start--> */}
        <section id="about" class="about-us ptb-100">
          <div class="container">
            <div class="row align-items-center justify-content-between g-4">
              <div class="col-md-6">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div>
                    <img src="img/ellipse.png" />
                  </div>
                  <p style={{ color: "#000", fontWeight: "400" }}>
                    Complete Anonymity
                  </p>
                  <div>
                    <img src="img/ellipse.png" />
                  </div>
                  <p style={{ color: "#000", fontWeight: "400" }}>
                    Community Support
                  </p>
                  <div>
                    <img src="img/ellipse.png" />
                  </div>
                  <p style={{ color: "#000", fontWeight: "400" }}>
                    End-to-End Encryption
                  </p>
                </div>
                <div class="about-content-left section-heading">
                  <h2
                    style={{
                      fontSize: "68px",
                      fontWeight: "500",
                      lineHeight: "74px",
                      marginBottom: "30px",
                    }}
                  >
                    Unburden Your Mind, Get the app today.
                  </h2>

                  <h5>Download App From</h5>
                  <div
                    class="action-btn mt-2"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <img
                      src="img/google-store.png"
                      style={{ marginRight: "10px" }}
                    />

                    <img src="img/apple-store.png" />
                  </div>

                  <div class="single-feature mb-4 mt-5">
                    <div class="icon-box-wrap d-flex align-items-center mb-2"></div>
                  </div>
                </div>
              </div>
              <div class="col-md-5 offset-xl-1">
                <div class="about-content-right">
                  <img
                    src="img/lagbaja-img-3.png"
                    alt="about us"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <footer class="footer-section">
        {/* <!--footer top start--> */}
        <div
          class="footer-top pt-60 background-img-2"
          style={{
            background: "#033036",
          }}
        >
          <div class="container">
            <div
              class="row justify-content-between"
              style={{ display: "flex", alignItems: "center" }}
            >
              <div class="col-md-12 col-lg-6 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div class="footer-nav-wrap text-white">
                  <img
                    src="img/footer-logo.png"
                    alt="footer logo"
                    width="150"
                    class="img-fluid mb-3"
                  />
                </div>
              </div>
              <div class="col-md-12 col-lg-6">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                  }}
                >
                  <p className="text-white" style={{ fontSize: "14px" }}>
                    ABOUT US
                  </p>
                  <p className="text-white" style={{ fontSize: "14px" }}>
                    CONTACT US
                  </p>
                  <p className="text-white" style={{ fontSize: "14px" }}>
                    PRIVACY POLICY
                  </p>
                  <p className="text-white" style={{ fontSize: "14px" }}>
                    TERMS AND CONDITION
                  </p>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <p
                    className="text-white"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      alignContent: "center",
                    }}
                  >
                    <img
                      src="img/phone.png"
                      style={{ height: "20px", marginRight: "5px" }}
                    />
                    09027202609
                  </p>
                  <p
                    className="text-white"
                    style={{
                      fontSize: "14px",
                      display: "flex",
                      alignContent: "center",
                    }}
                  >
                    <img
                      src="img/printshop.png"
                      style={{ height: "20px", marginRight: "5px" }}
                    />
                    lagbaja.app@gmail.com
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-bottom border-gray-light mt-5 py-3">
            <div class="container">
              <div class="row">
                <div class="col-md-6 col-lg-7">
                  <div class="copyright-wrap small-text">
                    <p class="mb-0 text-white">© lagbaja@2024</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
